// const script = document.createElement('script')
// const origin = window.location.origin.includes('hilook.com.cn')
// const originPre = window.location.origin.includes('higroup-ihaier-pre.hilook.com.cn')
// let staffToken = sessionStorage.getItem('staff-token')
// console.log(window.location.href)
// if (origin && !staffToken && window.location.href.indexOf('xueId') == -1) {
//   script.src = 'https://r.haier.net/assets/overlay/dts-fe/online-popup/index.js'
// } else if (!staffToken && window.location.href.indexOf('xueId') == -1){
//   script.src = 'https://r.haier.net/assets/daily/dts-fe/online-popup/2.0.1/index.js'
// }
// document.head.appendChild(script)
export default {
  data() {
    return {
      touchTimer: null,
      touchStartTime: 0,
      touchIdentifier: null
    };
  },
  mounted() {
    // 同时使用 contextmenu 和 touchhold
    document.addEventListener('contextmenu', this.handleContextMenu);
  },
  unmounted() {
    document.removeEventListener('contextmenu', this.handleContextMenu);
  },
  methods: {
    handleContextMenu(e) {
      const target = e.target;
      if (target.tagName.toLowerCase() === 'img') {
        e.preventDefault();
        return false;
      }
    }
  }
};