import { defineStore } from 'pinia'
import api from '@/api'
import plateform from '@/util/get-plateform'
//defineStore 是返回一个函数 函数命名最好有use前缀，根据函数来进行下一步操作
const useStore = defineStore('main', {
  state: () => ({
    myGroupList: [],
    moreGroupList: [],
    postsItem: {}, // 帖子点击进入存
    needRefresh: false, //需要更新动态
    userInfo: {
      email: '',
      headImg: '',
      mobile: '',
      name: '',
      staffId: '',
      id: '',
      banned: false,
      // 昵称头像
      nickAvatar: '',
      // 昵称
      nickname: ''
    }, //用户信息
    voteObj: {},
    noticeNum: 0, //消息数量
    throttle: false,
    scrollTopObj: {},
    chatList:[], // 聊天列表
    readedNumList:[], // 阅读进度
    loadedId:[],
    isScreenMobile: plateform.isMobile(),
    areaList: [],
    showWarning: false,
  }),
  actions: {
    // 我加入的小组
    async getMyGroupList() {
      // this.myGroupList = [];
      const { data } = await api.myGroupList(1)
      this.myGroupList = data.records
    },
    async getVoteList(type, refresh = false) {
      if (!this.voteObj['voteType_' + type] || refresh) {
        if (!this.voteObj['voteType_' + type]) this.voteObj['voteType_' + type] = []
        const { data } = await api.getVoteList(type)
        this.voteObj['voteType_' + type] = data
      }
    },
    // 发现更多小组
    async getMoreGroupList() {
      const { data } = await api.moreGroupList(1)
      this.moreGroupList = []
      setTimeout(() => {
        this.moreGroupList = data.records
      }, 0)
    },
    // 存入点击进入的帖子
    setPostsItem(item) {
      localStorage.setItem('postsItem', JSON.stringify(item))
      this.postsItem = item
    },
    // 获取用户信息
    async getInfo() {
      const { data } = await api.getUserInfo()
      this.userInfo = data
      localStorage.setItem('userInfo', JSON.stringify(data))
      // this.userInfo.name = '开心'
      // this.userInfo.nickname = '哈'
    },
    setScrollTopObj(key, value) {
      this.scrollTopObj[key] = value
    },
    setAreaList (list) {
      this.areaList = list
    }
  }
})

export default useStore
